import { AnalyticPageName } from '@package/sdk/src/analytics';
import { ItemPageFrom, useContentPageAnalytics } from '@package/sdk/src/analytics/composables/use-content-page-kmza';
import { usePlayerPageAnalytics } from '@package/sdk/src/analytics/composables/use-player-page-kmza';
import { MediaContentType } from '@package/sdk/src/api/content/types/content-type';
import * as qs from 'qs';

import { analyticService, routerService } from '../../index';
import { RouterPage } from '../../router/router';

interface IOpenContentPageOptions {
  contentType: MediaContentType;
  id: string;
  from?: ItemPageFrom;
  isRandomItem?: boolean;
  title: string;
  seasonNumber?: number;
  episodeNumber?: number;
  searchText?: string;
  numberInResults?: number;
  titlesSelectionName?: string;
  titlesSelectionPosition?: number;
  startOffset?: number;
  playlistTitle?: string;
  episodeId?: string;
  kinomTitle?: string;
  kinomId?: string;
}

interface IOpenPlayerPageOptions {
  contentType: MediaContentType;
  title?: string;
  episodeId?: string;
  id: string;
  from?: ItemPageFrom;
  kinomId?: string;
  playlistTitle?: string;
  serialId?: string;
  kinomTitle?: string;
  seasonIndex?: string;
  episodeIndex?: string;
}

export function useMediaContentActions() {
  const contentPageAnalytics = useContentPageAnalytics(analyticService.sender);
  const playerPageAnalytics = usePlayerPageAnalytics(analyticService.sender);

  const openContentPage = (options: IOpenContentPageOptions) => {
    const {
      contentType,
      id,
      from,
      title,
      seasonNumber,
      episodeNumber,
      searchText,
      numberInResults,
      titlesSelectionName,
      titlesSelectionPosition,
      startOffset,
      playlistTitle,
      kinomId,
      episodeId,
      kinomTitle,
      isRandomItem,
    } = options;

    switch (from) {
      case ItemPageFrom.ContinueWatch: {
        contentPageAnalytics.onGotoItemPage({
          contentType,
          itemId: id,
          kinomTitle,
          from: ItemPageFrom.ContinueWatch,
          title,
          kinomId,
          isRandomItem,
          episodeId,
          page: AnalyticPageName.Main,
        });
        break;
      }
      case ItemPageFrom.Promo: {
        contentPageAnalytics.onGotoItemPageFromPromoSlider({
          contentId: id,
          contentType,
          contentTitle: title,
          id: kinomId as string,
          seasonNumber,
          episodeNumber,
          startOffset: startOffset as number,
        });
        break;
      }
      case ItemPageFrom.Dsml: {
        contentPageAnalytics.onGotoItemPage({
          contentType,
          itemId: id,
          episodeId,
          kinomId,
          isRandomItem,
          episode: episodeNumber,
          season: seasonNumber,
          kinomTitle,
          from: ItemPageFrom.Dsml,
          title,
          page: AnalyticPageName.Main,
        });
        break;
      }
      case ItemPageFrom.Playlist: {
        contentPageAnalytics.onGotoItemPage({
          contentType,
          isRandomItem,
          itemId: id,
          episode: episodeNumber,
          season: seasonNumber,
          episodeId,
          kinomId,
          from: ItemPageFrom.Playlist,
          title,
          playlistTitle,
          kinomTitle,
          page: AnalyticPageName.Main,
        });
        break;
      }
      case ItemPageFrom.Search: {
        contentPageAnalytics.onGotoItemPageFromSearch({
          title,
          contentType,
          searchText,
          episode: episodeNumber,
          season: seasonNumber,
          episodeId,
          kinomId,
          kinomTitle,
          numberInResults,
          itemId: id,
          page: AnalyticPageName.Search,
        });
        break;
      }
      case ItemPageFrom.Compilation: {
        contentPageAnalytics.onGotoItemPageFromCompilation({
          itemId: id,
          episodeId,
          kinomId,
          page: analyticService.getAnalyticPageName(),
          titlesSelectionName: titlesSelectionName as string,
          titlesSelectionPosition: titlesSelectionPosition as number,
        });

        break;
      }
      default: {
        contentPageAnalytics.onGotoItemPage({
          itemId: id,
          title,
          episode: episodeNumber,
          season: seasonNumber,
          kinomId,
          kinomTitle,
          episodeId,
          isRandomItem,
          page: analyticService.getAnalyticPageName(),
          contentType,
        });
        break;
      }
    }

    return routerService.push({ name: RouterPage.MediaCardPage, params: { type: contentType, id } });
  };

  const openPlayerPage = (options: IOpenPlayerPageOptions) => {
    const {
      contentType,
      id,
      serialId,
      episodeIndex,
      seasonIndex,
      kinomTitle,
      kinomId,
      playlistTitle,
      from,
      episodeId,
      title,
    } = options;

    playerPageAnalytics.onGotoPlayerPage({
      contentType,
      itemId: serialId || id,
      episodeId,
      kinomTitle,
      playlistTitle,
      from,
      season: Number(seasonIndex) + 1,
      episode: Number(episodeIndex) + 1,
      kinomId,
      title: title as string,
    });

    return routerService.push({
      name: RouterPage.MediaCardPlayerPage,
      params: {
        id,
        type: contentType,
      },
      query: {
        seasonIndex,
        episodeIndex,
        episodeId,
      },
    });
  };

  const getContentPage = (options: IOpenContentPageOptions) => {
    const { id, contentType } = options;

    const resolvedRoute = routerService.resolve({
      name: RouterPage.MediaCardPage,
      params: {
        id,
        type: contentType,
      },
    });

    return resolvedRoute.fullPath;
  };

  const getPlayerPage = (options: IOpenPlayerPageOptions) => {
    const { contentType, id, serialId, episodeIndex, seasonIndex, episodeId } = options;

    const resolvedRoute = routerService.resolve({
      name: RouterPage.MediaCardPlayerPage,
      params: {
        id,
        type: contentType,
      },
      query: {
        seasonIndex,
        episodeIndex,
        serialId,
        episodeId,
      },
    });

    return resolvedRoute.fullPath;
  };

  return {
    getContentPage,
    openPlayerPage,
    openContentPage,
    getPlayerPage,
  };
}
