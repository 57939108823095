<template>
  <div ref="el" class="page-container" :class="$style.page">
    <section :class="$style.search">
      <span ref="anchorRef"></span>
      <div
        :class="{
          [$style.keyboardWrapper]: true,
          [$style.keyboardWrapperHidden]: isKeyboardHidden,
        }"
      >
        <VirtualKeyboard
          ref="keyboardRef"
          v-model:input="input"
          :keyboards="searchKeyboard"
          :placeholder="inputPlaceholder"
          :upper-case="isKeyboardUppercase"
          :symbols-upper-case="true"
          :debounce-ms="150"
          input-type="text"
          input-variant="secondary"
          @vue:mounted="onVNodeMounted"
          @active="onActivateKeyboard"
          @voice:recording:start="onVoiceRecordingStart"
          @voice:recording:stop="onVoiceRecordingStop"
        />

        <section v-if="isSearchContentShown" v-show="!isKeyboardHidden" ref="historyRef" :class="$style.history">
          <NavigatableItem
            v-for="(item, index) in history"
            :key="item.createdAt"
            :prop-parent-focus-key="FocusKeys.VIRTUAL_KEYBOARD"
            :tag="AppButton"
            variation="smart-button-secondary"
            :class="$style.historyButton"
            :active-class="$style.active"
            :text="item.query"
            :text-class-name="$style.text"
            @active="onActivateHistory"
            @click="onHistorySelect(item.query, index)"
          />
        </section>
      </div>
    </section>

    <search-content-page
      v-if="isSearchContentShown"
      ref="searchContentPage"
      :input="input"
      :history="history"
      :is-keyboard-hidden="isKeyboardHidden"
      :is-skeleton-shown="shouldShowSkeleton"
      :is-input-empty="isInputEmpty"
      @update:is-search-active="(val: boolean) => (isSearchActive = val)"
      @update:is-input-empty="(val: boolean) => (isInputEmpty = val)"
      @history:fetch="onHistoryFetch"
      @keyboard:hide="toggleKeyboardHidden(true)"
    />
  </div>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import useTransitionName from '@package/media-player/src/player/modules/animation/use-transition-name';
import { useSearchPageAnalytics } from '@package/sdk/src/analytics';
import { debounce, DisposableStore } from '@package/sdk/src/core';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  AlertMessageTypes,
  alertService,
  analyticService,
  customEventsService,
  FocusKeys,
  History,
  searchService,
  translate,
} from '@SMART/index';
import {
  computed,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  provide,
  ref,
  useTemplateRef,
  watch,
} from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import SearchContentPage from '@/components/search/SearchContentPage.vue';
import { getSearchKeyboard } from '@/components/virtual-keyboard/keyboards';
import VirtualKeyboard from '@/components/virtual-keyboard/VirtualKeyboard.vue';
import useSessionVariables from '@/sdk/session/use-session-variables';

const anchorRef = ref<HTMLElement>();

const disposableStore = new DisposableStore();
const searchPageAnalytics = useSearchPageAnalytics(analyticService.sender);
const { isAuth } = useSessionVariables();
const { transitionName } = useTransitionName();

const { el, focusKey, focusSelf } = useNavigatable({
  focusKey: FocusKeys.SEARCH_PAGE,
  preferredChildFocusKey: FocusKeys.VIRTUAL_KEYBOARD,
  forceFocus: true,
});
provide('parentFocusKey', focusKey.value);

const { isVNodeMounted: isSearchContentShown, onVNodeMounted } = useVNodeMounted({ withTimeout: true, timeout: 1000 });

const input = ref('');
const inputPlaceholder = ref(translate('pages.search.placeholder'));
const shouldUpdateContent = ref(true);
const isSearchActive = ref(true);

const shouldShowSkeleton = computed(() => isSearchActive.value);
const isInputEmpty = ref(true);

const search = debounce(() => {
  shouldUpdateContent.value = true;
}, ConstantsConfigInstanceSmartTV.getProperty('debounceTimeoutModernMs'));

watch(input, async () => {
  if (!input.value?.length) {
    keyboardRef.value.setUpperCase(true);
    isSearchActive.value = false;
    isInputEmpty.value = true;
    return;
  }

  keyboardRef.value.setUpperCase(false);
  searchPageAnalytics.onSearchRequestEntering(input.value);

  isSearchActive.value = true;
  isInputEmpty.value = false;

  search();
});

// Keyboard
const keyboardRef = ref();
const isKeyboardHidden = ref(false);
const isKeyboardUppercase = ref(true);

const onClear = (request: string) => {
  searchPageAnalytics.onClickSearchRequestCancel(request);
};

const searchKeyboard = getSearchKeyboard({ input, keyboardRef, onClear });

const toggleKeyboardHidden = (value: boolean) => {
  isKeyboardHidden.value = value;
};

function onActivateKeyboard() {
  inputPlaceholder.value =
    SpatialNavigation.getCurrentFocusKey() === FocusKeys.KEYBOARD_KEY(FocusKeys.VOICE_SEARCH)
      ? translate('pages.search.placeholderVoice')
      : translate('pages.search.placeholder');

  if (!isKeyboardHidden.value) {
    return;
  }

  toggleKeyboardHidden(false);
  customEventsService.setWheelAction({ dec: 'up', inc: 'down' });

  if (input.value) {
    SpatialNavigation.setFocus(FocusKeys.KEYBOARD_KEY(FocusKeys.CLEAR));
  }
}

const onVoiceRecordingStart = () => {
  input.value = '';
  inputPlaceholder.value = translate('pages.search.placeholderSpeak');
  searchPageAnalytics.onClickSearchVoiceMode();
};

const onVoiceRecordingStop = (value: string) => {
  inputPlaceholder.value = translate('pages.search.placeholderVoice');

  if (value) {
    searchPageAnalytics.onAutoSearchVoiceSuccess(value);
    return;
  }

  searchPageAnalytics.onAutoSearchVoiceError();

  alertService.addAlert({
    message: translate('pages.search.notRecognized'),
    type: AlertMessageTypes.ErrorVoiceSearch,
  });
};

const history = ref<History[]>([]);
const historyRef = ref();

const onActivateHistory = () => {
  toggleKeyboardHidden(false);
};

const onHistorySelect = (query: string, index: number) => {
  input.value = query;
  searchPageAnalytics.onClickSearchHistoryItem(query, index);
};

const onHistoryFetch = async () => {
  if (!isAuth.value) {
    return;
  }

  history.value = await searchService.getSearchHistory({ page: 1, perPage: 5 });
  searchPageAnalytics.onAutoSearchHistoryDisplayed();
};

const searchContentPage = useTemplateRef('searchContentPage');

onActivated(() => {
  SpatialNavigation.setFocus(FocusKeys.KEYBOARD_KEY('А'));
  searchContentPage.value?.restoreFocusKey(FocusKeys.SEARCH_PAGE);
});

onDeactivated(() => {
  customEventsService.setWheelAction({ dec: 'up', inc: 'down' });
});

onMounted(async () => {
  focusSelf();

  searchPageAnalytics.onShowSearchPage();

  return onHistoryFetch();
});

onBeforeUnmount(() => {
  disposableStore.dispose();
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';

.page {
  .title {
    @include f-subtitle-2;
  }
}

.keyboardWrapper {
  display: flex;
  flex-flow: row;

  &Hidden {
    position: relative;
    top: adjust.adjustPx(-600px);
  }
}

.search {
  display: flex;
  flex-flow: row;
  margin-top: adjust.adjustPx(20px);
  margin-bottom: adjust.adjustPx(40px);
  margin-left: adjust.adjustPx(45px);
}

.history {
  display: flex;
  flex-flow: column;

  margin-top: adjust.adjustPx(113.5px);
  margin-left: adjust.adjustPx(20px);
  overflow: hidden;

  &Button {
    margin-bottom: adjust.adjustPx(8px);
    width: adjust.adjustPx(521px);
    max-height: adjust.adjustPx(68px);
    border-radius: adjust.adjustPx(16px);
    border: none;
    background-color: transparent;
    justify-content: flex-start;
    min-height: adjust.adjustPx(68px);
  }

  .active {
    background-color: var(--color-bg-accent);
  }

  .text {
    display: block;
    width: 100%;
    text-align: start;

    @include f-body;
  }
}
</style>
