<template>
  <div class="page-container">
    <QrLogin v-if="type === 'qr'" @update:type="onTypeChange" />
  </div>
</template>

<script setup lang="ts">
import { useAccountPageAnalytics } from '@package/sdk/src/analytics';
import { analyticService } from '@package/smarttv-base/src';
import { onMounted, ref } from 'vue';

import QrLogin from './components/QrLogin.vue';

type AuthType = 'qr' | 'email';

const type = ref<AuthType>('qr');
const accountPageAnalytics = useAccountPageAnalytics(analyticService.sender);

const onTypeChange = (value: AuthType) => {
  type.value = value;
};

onMounted(() => {
  accountPageAnalytics.onShowAuthLoginTv();
});
</script>
