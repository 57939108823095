export enum AppLanguage {
  RU = 'ru',
  EN = 'en',
  AM = 'am',
}

export enum AppVariation {
  Ru = 'ru',
  Am = 'am',
}
