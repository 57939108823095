import useExperimentalFeature from '@PLAYER/player/modules/experimental-feature/use-experimental-feature';
import useState from '@PLAYER/player/modules/global/use-state';
import { MediaSourceTechBufferInfo } from '@PLAYER/player/tech/media-source-tech';
import useSafeAppConfig from '@PLAYER/player/versions/web/modules/config/use-safe-app-config';
import { throttledRef } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const VIDEO_STORE_NAME = 'video-ui';

const useVideoUIStore = defineStore(VIDEO_STORE_NAME, () => {
  const config = useSafeAppConfig();
  const { isLazyLoadingInteractionEnabled } = useExperimentalFeature();

  // Был ли плеер запущен (загружен плейлист)
  const [isInitialized, setIsInitialized] = useState(false);

  const prevDisplayedCurrentTime = ref(0);
  const displayedCurrentTime = ref(0);

  // Текущее время проигрывания
  const setDisplayedCurrentTime = (value: number) => {
    prevDisplayedCurrentTime.value = displayedCurrentTime.value;

    displayedCurrentTime.value = value;
  };

  const exactDisplayedCurrentTime = throttledRef(displayedCurrentTime, 1000);

  const [isVideoPreviewShown, setIsVideoPreviewShown] = useState(true);
  const [currentPlaybackRate, setCurrentPlaybackRate] = useState(1);

  const [audioContextVolume, setAudioContextVolume] = useState(0);
  const [duration, setDuration] = useState<number>(0);
  const [volume, setVolume] = useState<number>(0);
  const [isPlaying, setIsPlay] = useState<boolean>(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isSeeked, setIsSeeked] = useState(false);

  const isFullscreenEnabledPrevState = ref(false);
  const isFullScreenEnabled = ref(false);
  const isVideoPreviewShownInner = ref(true);

  const isKinomPreviewShown = computed(() => isVideoPreviewShownInner.value && isLazyLoadingInteractionEnabled.value);

  const setIsFullScreenEnabled = (value: boolean) => {
    isFullscreenEnabledPrevState.value = isFullScreenEnabled.value;
    isFullScreenEnabled.value = value;
  };

  const [isPictureInPictureEnabled, setIsPictureInPictureEnabled] = useState<boolean>(false);

  const [isRemotePlayAvailable, setIsRemotePlayAvailable] = useState(false);
  const [isPlayingOnRemoteDevice, setIsPlayingOnRemoteDevice] = useState(false);

  const [playingRemoteDeviceName, setPlayingRemoteDeviceName] = useState('');
  const [bufferInfo, setBufferInfo] = useState<MediaSourceTechBufferInfo>({ start: 0, length: 0 });

  const [isAutoStartLoad, setIsAutoStartLoad] = useState(config.autoStartLoad);

  return {
    audioContextVolume,
    setAudioContextVolume,
    isSeeked,
    setIsSeeked,
    isAutoStartLoad,
    setIsAutoStartLoad,
    isSeeking,
    setIsSeeking,
    setPlayingRemoteDeviceName,
    playingRemoteDeviceName,
    isPlayingOnRemoteDevice,
    setIsPlayingOnRemoteDevice,
    currentPlaybackRate,
    setCurrentPlaybackRate,
    bufferInfo,
    setBufferInfo,
    isVideoPreviewShown,
    setIsVideoPreviewShown,
    isRemotePlayAvailable,
    setIsRemotePlayAvailable,
    isPictureInPictureEnabled,
    isFullscreenEnabledPrevState,
    setIsPictureInPictureEnabled,
    isInitialized,
    setIsInitialized,
    exactDisplayedCurrentTime,
    displayedCurrentTime,
    setDisplayedCurrentTime,
    duration,
    setDuration,
    volume,
    prevDisplayedCurrentTime,
    setVolume,
    isPlaying,
    setIsPlay,
    isFullScreenEnabled,
    setIsFullScreenEnabled,
    isVideoPreviewShownInner,
    isKinomPreviewShown,
  };
});

export default useVideoUIStore;
