import DOMPurify from 'isomorphic-dompurify';
import { marked } from 'marked';

export default function useMarked() {
  const renderer = new marked.Renderer();
  const doubleEqualAround = /==([^=]+)==/g;

  // Удаляем обёртку <p></p> и добавляем новое правило
  renderer.paragraph = (text: string) => text;
  renderer.text = (text: string) => text.replace(doubleEqualAround, '<span class="highlight">$1</span>');

  // Изменяем существующее правило
  renderer.del = (text: string) => `<span class="striked">${text}</span>`;

  // Удаляем обёртку <p></p> и добавляем новое правило
  renderer.paragraph = (text: string) => text;
  renderer.text = (text: string) => text.replace(doubleEqualAround, '<span class="highlight">$1</span>');

  // Изменяем существующее правило
  renderer.del = (text: string) => `<span class="striked">${text}</span>`;

  // Разрешаем класс в sanitizeHtml
  const rules: DOMPurify.Config = {
    ALLOWED_TAGS: ['span', 'a'],
    ALLOWED_ATTR: ['class', 'href'],
  };

  const renderMarkdown = (markdownText: string) => {
    const renderedHtml = marked(markdownText, { renderer }) as string;

    return DOMPurify.sanitize(renderedHtml, rules);
  };

  return {
    renderMarkdown,
  };
}

if (import.meta.vitest) {
  const { it, expect, beforeAll } = import.meta.vitest;

  const highlightedText = '==text==';
  const strikedText = '~~text~~';
  const highlightedAndStrikedText = '~~==text==~~';

  const highlightedHTML = '<span class="highlight">text</span>';
  const strikedHTML = '<span class="striked">text</span>';
  const highlightedAndStrikedHTML = '<span class="striked"><span class="highlight">text</span></span>';

  const bloatedHTML =
    '<img src="http://moshenniki.vip/tracking-your-soul" width="1" height="1" class="tracking-pixel" alt="Tracking Pixel" onclick="alert(\'XSS Attack\')" />';

  let renderMarkdownFunc;

  beforeAll(async () => {
    const { renderMarkdown } = useMarked();
    renderMarkdownFunc = renderMarkdown;
  });

  it('should return highlightedHTML', () => {
    expect(renderMarkdownFunc(highlightedText)).toEqual(highlightedHTML);
  });

  it('should return strikedHTML', () => {
    expect(renderMarkdownFunc(strikedText)).toEqual(strikedHTML);
  });

  it('should return highlightedAndStrikedHTML', () => {
    expect(renderMarkdownFunc(highlightedAndStrikedText)).toEqual(highlightedAndStrikedHTML);
  });

  it('should return empty string', () => {
    expect(renderMarkdownFunc(bloatedHTML)).toEqual('');
  });
}
