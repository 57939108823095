import { isUndefinedOrNull } from '@package/sdk/src/core';
import useState from '@PLAYER/player/modules/global/use-state';
import { LoaderType } from '@PLAYER/player/modules/hooks/use-loader-actions';
import { debouncedRef } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, Ref, ref } from 'vue';

type PopupComponentName = 'PopupSettingsMenu' | 'PopupEpisodesMenu' | 'SmartTvOnboardingPopupControls';

const LAYOUT_STORE_NAME = 'layout';

interface ContextMenuShownOptions {
  x: number;
  y: number;
}

const useLayoutStore = defineStore(LAYOUT_STORE_NAME, () => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [currentPopupComponentName, setPopup] = useState<PopupComponentName | null>(null);
  const isShownPopup = computed(() => !isUndefinedOrNull(currentPopupComponentName.value));

  const [isSettingsPopupShown, setIsSettingsPopupShown] = useState(false);
  const [isEpisodesPopupShown, setIsEpisodesPopupShown] = useState(false);

  const [isShownPoster, setIsShownPoster] = useState(true);
  const [loaderType, setLoader] = useState<LoaderType>(null);
  const [isDebugVisible, setIsDebugVisible] = useState(false);

  const [isNextContentEpisodeShown, setIsNextContentEpisodeShown] = useState(false);
  const [isAdultContentWarningVisible, setIsAdultContentWarningVisible] = useState(false);

  const [isUserWatchedFreeThresholdTime, setIsUserWatchedFreeThresholdTime] = useState(false);
  const [isNextEpisodeElementHoveredRaw, setIsNextEpisodeElementHovered] = useState(false);

  const isNextEpisodeElementHovered = debouncedRef(isNextEpisodeElementHoveredRaw, 250);

  const isShownLoader = computed(() => Boolean(loaderType.value));

  const [isDocumentVisible, setIsDocumentVisible] = useState(true);
  const [isPlayerIntersect, setIsPlayerIntersect] = useState(false);
  const [contextMenuOptions, setContextMenuOptions] = useState<ContextMenuShownOptions | undefined>(undefined);

  const [isLinkedKinomShown, setIsLinkedKinomShown] = useState(false);

  const ignorePopupElements = ref<Ref<HTMLElement>[]>([]);
  const addIgnorePopupElement = (el: Ref<HTMLElement>) => {
    ignorePopupElements.value.push(el);
  };

  return {
    isNextEpisodeElementHovered,
    setIsNextEpisodeElementHovered,
    isUserWatchedFreeThresholdTime,
    setIsUserWatchedFreeThresholdTime,
    isLinkedKinomShown,
    setIsLinkedKinomShown,
    isEpisodesPopupShown,
    setIsEpisodesPopupShown,
    isSettingsPopupShown,
    setIsSettingsPopupShown,
    isNextContentEpisodeShown,
    setIsNextContentEpisodeShown,
    isAdultContentWarningVisible,
    setIsAdultContentWarningVisible,
    contextMenuOptions,
    setContextMenuOptions,
    isDocumentVisible,
    setIsDocumentVisible,
    isPlayerIntersect,
    setIsPlayerIntersect,
    addIgnorePopupElement,
    ignorePopupElements,
    isDebugVisible,
    setIsDebugVisible,
    currentPopupComponentName,
    isShownPopup,
    setPopup,
    isFocused,
    setIsFocused,
    setLoader,
    isShownLoader,
    loaderType,
    isShownPoster,
    setIsShownPoster,
  };
});

export default useLayoutStore;
