<template>
  <div ref="el" :class="$style.auth">
    <div :class="$style.instructions">
      <qr-login-image-section
        :code="code"
        @update:code="(e: string) => (code = e)"
        @vue:mounted="onQrLoginImageSectionMounted"
      />

      <qr-login-code-section v-if="isQrLoginImageSectionMounted" :code-url="codeURL" :code="code" />
    </div>

    <section v-if="isQrLoginImageSectionMounted" :class="$style.controls" @vue:mounted="focusSelf">
      <NavigatableItem
        :tag="AppButton"
        :active-class="$style.active"
        :text="$t('pages.auth.qrLogin.loginManually')"
        @click="onChangeLoginMethod"
      />

      <NavigatableItem
        :tag="AppButton"
        variation="smart-button-secondary"
        :active-class="$style.active"
        :text="$t('pages.auth.qrLogin.helpButton')"
        @click="onToggleHelp(true)"
      />
    </section>

    <FullScreenModal v-if="isHelpSectionShown" @close="onToggleHelp(false)">
      <HelpContacts />
    </FullScreenModal>
  </div>
</template>

<script setup lang="ts">
import {
  AnalyticEventNameNew,
  AnalyticPageName,
  useAccountPageAnalytics,
  useAuthPageAnalytics,
} from '@package/sdk/src/analytics';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { analyticService, environmentService, FocusKeys, RouterPage, routerService, WebAppRoute } from '@SMART/index';
import { onMounted, provide, ref } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import FullScreenModal from '@/components/modal/FullScreenModal.vue';
import QrLoginCodeSection from '@/pages/auth/components/QrLoginCodeSection.vue';
import QrLoginImageSection from '@/pages/auth/components/QrLoginImageSection.vue';
import HelpContacts from '@/pages/settings/components/HelpContacts.vue';

const { el, focusSelf, focusKey } = useNavigatable({
  focusKey: FocusKeys.QR_LOGIN,
  forceFocus: true,
  saveLastFocusedChild: false,
});
provide('parentFocusKey', focusKey.value);

const { isVNodeMounted: isQrLoginImageSectionMounted, onVNodeMounted: onQrLoginImageSectionMounted } = useVNodeMounted({
  withTimeout: true,
  timeout: 500,
});
const authPageAnalytics = useAuthPageAnalytics(analyticService.sender);
const accountPageAnalytics = useAccountPageAnalytics(analyticService.sender);

const code = ref('');
const isHelpSectionShown = ref(false);

const webAppURL = environmentService.getVariable('webAppURL');
const codeURL = (webAppURL + WebAppRoute.SmartTvCode).replace('https://', '');

const onChangeLoginMethod = () => {
  accountPageAnalytics.onClickAuthLoginTv();
  routerService.push({ name: RouterPage.LoginPage });
};

const onToggleHelp = (state: boolean) => {
  if (state) {
    analyticService.sendEvent({
      name: AnalyticEventNameNew.GotoContactsPage,
      page: AnalyticPageName.Account,
    });
  }

  isHelpSectionShown.value = state;
};

onMounted(() => {
  authPageAnalytics.onShowAuthPage();
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.auth {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;

  padding: {
    bottom: adjust.adjustPx(60px);
    left: adjust.adjustPx(60px);
    right: adjust.adjustPx(60px);
  }
}

.instructions {
  display: flex;
  flex-flow: row;
  flex-grow: 1;

  @include smartTvFonts.SmartTvBody-2;
}

.controls {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;

  > :first-child {
    margin-right: adjust.adjustPx(24px);
  }

  margin-top: adjust.adjustPx(24px);
}

.active {
  color: var(--color-notheme-text-accent);
}
</style>
