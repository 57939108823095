import { lifecycleService } from '../index';

export const useAppInitialization = () => {
  const loadAppWithTimeout = (timeoutMs = 500) => {
    setTimeout(() => lifecycleService.onAppLoaded(), timeoutMs);
  };

  return {
    loadAppWithTimeout,
  };
};
