<template>
  <div ref="el" :class="$style.wrapper">
    <article :class="$style.settings">
      <section :class="$style.navigation">
        <section>
          <NavigatableItem
            v-if="isMainProfile"
            :tag="AppButton"
            :focus-key="FocusKeys.SETTINGS_SUBSCRIBE_TAB"
            variation="smart-button-secondary"
            :class="$style.navigationButton"
            :active-class="$style.active"
            :text="$t('pages.settings.subscribeButton')"
            @click="onSelectSection($RouterPage.Subscriptions)"
          />
          <NavigatableItem
            :tag="AppButton"
            variation="smart-button-secondary"
            :focus-key="FocusKeys.SETTINGS_PERSONAL_DATA_TAB"
            :class="$style.navigationButton"
            :active-class="$style.active"
            :text="$t('pages.settings.personDataButton')"
            @click="onSelectSection($RouterPage.PersonalData)"
          />
          <NavigatableItem
            :tag="AppButton"
            :focus-key="FocusKeys.SETTINGS_CONTACTS_TAB"
            variation="smart-button-secondary"
            :class="$style.navigationButton"
            :active-class="$style.active"
            :text="$t('pages.settings.contactsButton')"
            @click="onSelectSection($RouterPage.HelpContacts)"
          />
        </section>
        <NavigatableItem
          :tag="AppButton"
          variation="smart-button-secondary"
          :class="$style.navigationButton"
          :active-class="$style.active"
          :text="$t('pages.settings.logoutButton')"
          @click="onLogout"
        />
      </section>

      <section :class="$style.content">
        <router-view />
      </section>
    </article>

    <FullScreenModal v-if="logoutModalShown">
      <AppExitModal
        translation-page="modals.logout"
        @close="logoutModalResolve(false)"
        @exit="logoutModalResolve(true)"
      />
    </FullScreenModal>
  </div>
</template>

<script setup lang="ts">
import { AnalyticPageName, useAccountPageAnalytics } from '@package/sdk/src/analytics';
import { ProfileType } from '@package/sdk/src/api';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  analyticService,
  authService,
  FocusKeys,
  RouterPage,
  routerService,
  SessionGetters,
  SessionState,
  storeToRefs,
  useSessionStore,
} from '@SMART/index';
import { computed, onMounted, provide, ref } from 'vue';
import { useRoute } from 'vue-router';

import AppButton from '@/components/app-button/AppButton.vue';
import AppExitModal from '@/components/modal/AppExitModal.vue';
import FullScreenModal from '@/components/modal/FullScreenModal.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

const sessionStore = useSessionStore();
const accountPageAnalytics = useAccountPageAnalytics(analyticService.sender);
const { profile } = storeToRefs<SessionState, SessionGetters, unknown>(sessionStore);

const isMainProfile = computed(() => profile.value?.kind === ProfileType.MAIN);

const { el, focusKey } = useNavigatable({
  focusKey: FocusKeys.SETTINGS_PAGE,
});
provide('parentFocusKey', focusKey.value);

const route = useRoute();

onMounted(async () => {
  await sessionStore.fetchUser({ forceFetchUser: true });

  switch (route.name) {
    case RouterPage.Subscriptions:
      SpatialNavigation.setFocus(FocusKeys.SETTINGS_SUBSCRIBE_TAB);
      break;
    case RouterPage.PersonalData:
      SpatialNavigation.setFocus(FocusKeys.SETTINGS_PERSONAL_DATA_TAB);
      break;
    case RouterPage.HelpContacts:
      SpatialNavigation.setFocus(FocusKeys.SETTINGS_CONTACTS_TAB);
      break;
  }
});

const onSelectSection = (name: RouterPage) => {
  switch (name) {
    case RouterPage.Subscriptions:
      accountPageAnalytics.onGotoSubscribePage(AnalyticPageName.Account);
      break;
    case RouterPage.HelpContacts:
      accountPageAnalytics.onGotoSupportPage(AnalyticPageName.Account);
      break;
  }

  return routerService.push({ name });
};

const logoutModalShown = ref(false);
const logoutModalResolve = ref<Function>(() => {});

const showAppExitModal = async () => {
  logoutModalShown.value = true;

  return new Promise((res) => {
    logoutModalResolve.value = (value: boolean) => {
      logoutModalShown.value = false;

      value ? accountPageAnalytics.onClickExitConfirm() : accountPageAnalytics.onClickExitCancel();

      res(value);
    };
  });
};

const onLogout = async () => {
  const res = await showAppExitModal();
  if (!res) {
    return;
  }

  accountPageAnalytics.onClickExit();

  await authService.signOut();

  return routerService.push({ name: RouterPage.MainPage });
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 0 adjustPx(138px);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.settings {
  display: flex;
  height: 100%;
}

.navigation {
  display: flex;
  align-items: flex-start;
  padding: adjustPx(60px) 0 adjustPx(68px) 0;
  width: adjustPx(544px);
  height: 100%;
  background: var(--color-states-bg-secondary-pressed);
  flex-flow: column;
  justify-content: space-between;

  &Button {
    margin: 0 adjustPx(40px);
    width: adjustPx(466px);
    border-radius: adjustPx(24px);
    border-color: transparent;
    background-color: transparent;
    justify-content: flex-start;
    text-align: start;
  }
}

.content {
  display: flex;
  flex-flow: column;
  padding-top: adjustPx(90px);
  padding-left: adjustPx(150px);
}

.active {
  box-shadow: none;
}
</style>
