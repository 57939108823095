<template>
  <section ref="el" :class="$style.notification">
    <slot name="icon">
      <StarIcon :class="$style.icon" />
    </slot>
    <div :class="$style.wrapper">
      <slot name="title">
        <h3 :class="$style.title">{{ title }}</h3>
      </slot>
      <slot name="subtitle">
        <h4 :class="$style.subtitle">{{ subtitle }}</h4>
      </slot>
      <slot name="actions"></slot>
    </div>
  </section>
</template>

<script setup lang="ts">
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import StarIcon from '@SMART/assets/icons/40x40/kinom.svg';
import { FocusKeys } from '@SMART/index';
import { provide } from 'vue';

interface Props {
  title?: string;
  subtitle?: string;
}

defineProps<Props>();

const { focusKey, el } = useNavigatable({
  focusKey: FocusKeys.MY_CHANNEL_NOTIFICATIONS_ACTIONS,
  hasGlobalAccess: true,
});

provide('parentFocusKey', focusKey.value);
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.notification {
  z-index: map-get($map: $layers, $key: --z-index-pseudo-elements);
  display: flex;
  align-items: flex-start;
  padding: adjustPx(32px);
  width: adjustPx(770px);
  border-radius: adjustPx(24px);
  background: var(--color-bg-modal);

  .icon {
    width: adjustPx(32px);
    min-width: adjustPx(32px);
    height: adjustPx(32px);
    min-height: adjustPx(32px);
    margin-top: adjustPx(8px);
    margin-right: adjustPx(16px);
    color: var(--color-notheme-bg-accent);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .title {
    color: var(--color-notheme-text-primary);

    @include f-label-2;
  }

  .subtitle {
    margin-top: adjustPx(12px);
    color: var(--color-notheme-text-primary);

    @include f-body-3;
  }
}
</style>
